#banner {
    height: 750px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

#banner.small {
    height: 400px;
}

.botao {
    border: 0;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
}

.botoes {
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botaoInscrever {
    background-color: #FF6600;
    color: white;
}

.botaoInscrever:hover {
    background-color: #FFBD1A;
}

.botaoLogin {
    background-color: #8A36D9;
    color: white;
}

.botaoLogin:hover {
    background-color: #3CF517;
}

#banner img {
    height: 550px;
}

#banner.small img {
    height: 300px;

}

#faixa1 {
    background-color: #3CF517;
}

#faixa2 {
    background-color: #A343FF;
}

#faixa3 {
    background-color: #FF6600;
}

#faixa4 {
    background-color: #FFBD1A;
}

#faixa5 {
    background-color: #F082F9;

}

.faixa {
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.faixa img {
    height: 40px;
}

#espaco-video {
    background-color: #000;
    background-image: url('../../assets/images/fundo-preto-top.png');
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 400px;
    padding-top: 100px;
    padding-bottom: 50px;
    text-align: center;
}

#video-fake {
    width: 1080px;
    max-width: 100%;
    background-color: rgb(177, 177, 177);
    margin: 0 auto;
    height: 600px;
}

#itens-elementos-container {
    background-color: #000;
    padding-bottom: 50px;
}

#itens-elementos {
    margin: 0 auto;
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#itens-elementos img {
    display: block;
    height: 70px;
    margin: 0 auto;
}

#img-biblia {
    height: 55px !important;
}

#div-qrCode {
    width: 300px;
    text-align: center;
}

#sobre-o-acampa-container {
    padding-top: 80px;
    background-color: black;
    background-image: url('../../assets/images/fundo-preto-bot.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    padding-bottom: 100px;
}

#sobre-o-acampa-texto {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    width: 900px;
    margin: 0 auto;
    color: white;
    text-align: justify;
}

.laranja {
    color: #FF6600;
}

.verde {
    color: #3CF517;
}

.amarelo {
    color: #FFBD1A;
}

.roxo {
    color: #8A36D9;
}

.data-e-valor-container {
    background-color: #5DF1FA;
    background-image: url('../../assets/images/fundo-azul-top.png');
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 400px;
    padding-top: 100px;
    text-align: center;
}

.data-e-valor {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    font-size: 14px;
}

.data-e-valor>div {
    background-image: url('../../assets/images/card.png');
    background-repeat: no-repeat;
    background-size: contain;
    height: 220px;
    width: 300px;
    margin-left: 25px;
    margin-right: 25px;
    font-weight: bold;
    font-size: 20px;
}

.data-e-valor>div:hover {
    color: #393d39;
}

.data,
.local,
.valor {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#localizacao {
    margin-top: 90px;
}

#localizacao iframe {
    width: 100%;
    height: 400px;
}

#rodape {
    background-color: black;
    color: white;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

#rodape img {
    height: 50px;
}

#content-container {
    background-color: black;
    color: white;
    min-height: 400px;
}

#content {
    width: 1200px;
    max-width: 100% !important;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 600px;
}

.ant-steps .ant-steps-item-active .ant-steps-item-icon {
    background-color: #FDB40F;
    border-color: #d1960c;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff;
}

.ant-steps .ant-steps-item-active .ant-steps-item-icon .ant-steps-icon {
    color: black;
}

.ant-steps .ant-steps-item-active .ant-steps-item-icon {
    font-weight: bold;
}

.ant-steps-item-title {
    color: white !important;
}

.ant-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #FDB40F;
}

.ant-steps .ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #fff;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #FDB40F;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    color: #FDB40F;
}

.ant-btn-primary {
    background-color: #FDB40F;
    border-color: #FDB40F;
    color: black;
}

.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    background-color: #d1960c;
    border-color: #d1960c;
    color: black;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #FDB40F;
    border-color: #FDB40F;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: white;
}

.ant-input-outlined.ant-input-disabled,
:where(.css-dev-only-do-not-override-1r1irrm).ant-input-outlined[disabled] {
    background-color: #d6d6d6;
    border-color: #d6d6d6;
    color: white;
}

#termos {
    max-height: 400px;
    width: 100%;
    overflow-y: auto;
    background-color: white;
    padding: 20px 40px !important;
    text-align: justify;
}

@media (max-width: 768px) {
    #div-qrCode {
        width: 100%;
    }

    #termos {
        padding: 20px 20px !important;
    }

    #content {
        width: calc(100% - 40px);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .data-e-valor {
        flex-direction: column;
    }

    .data-e-valor>div {
        padding-right: 70px;
        padding-left: 70px;
        margin-bottom: 30px;
        width: 170px;
    }

    #sobre-o-acampa-container {
        padding-bottom: 50px;
    }

    #espaco-video {
        padding-top: 50px;
        padding-bottom: 0px;
        min-height: 300px;
    }

    #sobre-o-acampa-texto {
        width: 80%;
    }

    #itens-elementos img {
        height: 50px;
    }

    #img-biblia {
        height: 45px !important;
    }

    #itens-elementos {
        width: 300px;
    }

    #video-fake {
        height: 250px;
        width: 370px;
    }

    #banner {
        height: 500px;
    }

    #banner.small {
        height: 250px;
    }

    #banner img {
        height: 300px;
    }

    #banner.small img {
        height: 200px;
    }

    .faixa img {
        height: 20px;
    }
}